import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import i1 from "./assets/images/1.gif";

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: #331e32;
  padding: 10px;
  font-weight: bold;
  color: #fff;
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: -1px 5px 9px 7px rgba(7, 70, 95, 1);
  -moz-box-shadow: 0px 6px 0px -2px rgba(13, 39, 49, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledImg = styled.img`
  width: 200px;
  height: 200px;
  @media (min-width: 267px) {
    width: 350px;
    height: 350px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [feedback, setFeedback] = useState("");
  const [claimingNft, setClaimingNft] = useState(false);

  const claimNFTs = (_amount) => {
    if (_amount <= 0) {
      return;
    }
    setFeedback("Minting your Warrior...");
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(blockchain.account, _amount)
      .send({
        //gasLimit: "285000",
        to: "0xc4B79AFCf656B98CE530C50Af8d3faC6fa945fbe",
        from: blockchain.account,
        value: blockchain.web3.utils.toWei((5 * _amount).toString(), "ether"),
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        setFeedback(
          "Welcome to the club! Go visit (beta.Degen.Watch) to see your collection."
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen style={{ backgroundColor: "var(--black"}}>
      <s.Container flex={1} ai={"center"} style={{ padding: 24 }}>
        <s.TextTitle
          style={{ textAlign: "center", fontSize: 65, fontWeight: "bold", color: "var(--light-grey)" }}>
          Welcome to the Joker!
        </s.TextTitle>
        <s.SpacerMedium/>
        <ResponsiveWrapper flex={1} style={{ padding: 10 }}>
          <s.Container flex={1} jc={"left"} ai={"center"}>
            <StyledImg alt={"stick fight"} src={i1} />
            <s.SpacerMedium />
            <s.TextTitle style={{ textAlign: "center", fontSize: 45, color: "var(--light-grey)", fontWeight: "bold" }}>
              {data.totalSupply}/5000
            </s.TextTitle>
          </s.Container>
          <s.SpacerMedium />
          <s.Container flex={1} jc={"center"} ai={"center"} color={ "var(--light-grey)"} >
            {Number(data.totalSupply) == 5000 ? (
              <>
                <s.TextTitle style={{ textAlign: "center" }}>
                  The sale has ended.
                </s.TextTitle>
                <s.SpacerSmall />
                <s.TextDescription style={{ textAlign: "center" }}>
                  You can still find Stick Warriors on{" "}
                  <a
                    target={"_blank"}
                    href={"https://beta.degen.watch/"}
                    href={""}
                  >
                
                    Degen.Watch
                  </a>
                </s.TextDescription>
              </>
            ) : (
              <>
                <s.TextTitle style={{ textAlign: "center", fontSize: 60 }}>
                  1 Warrior Cost
                </s.TextTitle>
                <s.TextSubTitle style={{ textAlign: "center", fontSize: 40, color: "var(--light-grey)" }}>
                  5 FTM 
                </s.TextSubTitle>
                <s.SpacerXSmall />
                <s.TextDescription style={{ textAlign: "center", fontSize: 45 }}>
                  Get Ready to Battle!
                </s.TextDescription>
                <s.SpacerXSmall />
                <s.TextDescription style={{ textAlign: "center", fontSize: 16, color: "var(--light-grey)" }}>
                  (Battle Arena Coming Next...!)
                </s.TextDescription>
                <s.SpacerSmall />
                <s.TextSubTitle style={{ textAlign: "center", fontSize: 18 }} >
                  {feedback}
                </s.TextSubTitle>
                <s.SpacerMedium />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription style={{ textAlign: "center", fontSize: 18, color: "var(--blue)"}}>
                      Please Connect to the Fantom network
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextSubTitle style={{ textAlign: "center", fontSize: "10" }}>
                          {blockchain.errorMsg}
                        </s.TextSubTitle>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <s.Container ai={"center"} jc={"center"} fd={"row"} >
                    <StyledButton style={{ fontSize: 18 }}
                      disabled={claimingNft ? 1 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        claimNFTs(1);
                        getData();
                      }}
                      
                   >  
                   
                     {claimingNft ? "HA-HA" : "BUY 1"}
                    </StyledButton>
                    <s.SpacerSmall />
                    <StyledButton style={{ fontSize: 18 }}
                      disabled={claimingNft ? 1 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        claimNFTs(5);
                        getData();
                      }}
                    >
                    
                      {claimingNft ? "BUSY" : "BUY 5"}
                    </StyledButton>
                    <s.SpacerSmall />
                    <StyledButton style={{ fontSize: 18 }}
                      disabled={claimingNft ? 1 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        claimNFTs(10);
                        getData();
                      }}
                   >
                      {claimingNft ? "HA-HA" : "BUY 10"}
                    </StyledButton>
                  </s.Container>
                )}
              </>
            )}
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerSmall/>
        <s.Container jc={"center"} ai={"center"} style={{ width: "100%" }} >
          <s.TextDescription style={{ textAlign: "center", fontSize: 33}} >
          <s.Container ai={"center"} jc={"center"}  > 
            Please make sure you are connected to
            </s.Container>
            <s.TextSubTitle style={{ fontSize: 20, color: "var(--blue)" }}> 
            (Fantom Mainnet)
            </s.TextSubTitle> 
            also make sure you are on the correct address. 
           </s.TextDescription>
           <s.SpacerXSmall/>
          <s.Container ai={"center"} jc={"center"} fd={"row"} > 
          <s.TextSubTitle  style={{ fontSize: 25, color: "var(--blue)" }} >
            note:
          </s.TextSubTitle>
            <s.SpacerXSmall/>
          <s.TextDescription style={{ fontSize: 20, color: "var(--light-grey)" }} >
            Once you make A Purchase. You can't undo this action.
          </s.TextDescription>
          </s.Container>
          </s.Container>
          </s.Container>
          <s.SpacerSmall />
          <s.Container ai={"center"} jc={"center"} fd={"row"}> 
          <s.Container  > 
          <s.TextDescription style={{ fontSize: 21, color: "#331e32" }}> 
          <a href="https://github.com/Daadesigner">Github</a>
          </s.TextDescription>
          </s.Container>
          <s.SpacerLarge/>
          <s.Container >
          <s.TextDescription style={{ fontSize: 21, color: "#331e32" }}> 
          <a href=
          "https://ftmscan.com/address/0xc4b79afcf656b98ce530c50af8d3fac6fa945fbe#code">
            Contract</a>
          </s.TextDescription>
          </s.Container>
          <s.SpacerLarge/>
          <s.Container>
          <s.TextDescription style={{ fontSize: 21, color: "#331e32" }}>
             <a href="https://twitter.com/thejoker_dao/">Twitter</a>
          </s.TextDescription>
          </s.Container>
          <s.SpacerMedium/>
          <s.Container >
          <s.TextDescription style={{ fontSize: 21, color: "#331e32" }}> 
           <a href="https://discord.gg/j7W2pdUXjq">Discord</a>
          </s.TextDescription>
          </s.Container>
        </s.Container>
        <s.SpacerMedium/> 
    </s.Screen>
 );
}
export default App;
